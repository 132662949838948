import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { RichText } from "prismic-reactjs"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import useWindowSize from '../hooks/useWindowSize'
import VisibilitySensor from 'react-visibility-sensor'

const Front = ({ content }) => {
  const { width } = useWindowSize();
  if (!content) return null
  return (
    <div>
      <SEO 
        image={content[0].node.body[0].primary.front_image.url+"&w=1200"}
        title={'Icelandic Spirits'} 
      />
      {content.map((content) => {
        return(
          <div className="frontContent" key={content.node._meta.id} >
            <h1 className="frontTitle">{RichText.asText(content.node.title)}<span className="dot">.</span></h1>
            <div className="frontImages">
              {content.node.body.map((images, index) =>{
                return(
                  <VisibilitySensor>
                     {({isVisible}) =>
                        <Link className="aBlock" to={"/"+images.primary.link_to_product._meta.uid} key={index}  aria-label={images.primary.link_to_product._meta.uid}>
                          <Img  className="frontImage" fadeIn durationFadeIn={2000} loading={isVisible ? "eager" : "lazy"} fluid={width > 600 ? images.primary.d_front_imageSharp.childImageSharp.fluid : images.primary.m_front_imageSharp.childImageSharp.fluid} />
                        </Link>
                      }
                     </VisibilitySensor>
                )
                })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ({ data }) => {
  const content = data.prismic.allHomes.edges
  if (!content) return null

  return (
      <Layout>
        <Front content={content} />
      </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allHomes(first: 1) {
        edges {
          node {
            title
            _meta {
              id
            }
            body {
            ... on PRISMIC_HomeBodyFrontimage {
              primary {
                link_to_product {
                  _linkType
                  ... on PRISMIC_Product {
                    _meta {
                      uid
                    }
                  }
                }
                d_front_imageSharp: front_imageSharp {
                  childImageSharp {
                    fluid (maxWidth: 350, quality: 100){
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                m_front_imageSharp: front_imageSharp {
                  childImageSharp {
                    fluid (maxWidth: 220, quality: 100){
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                front_image
                }
              }
            }
          }
        }
      }
    }
  }
`